// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blogue-js": () => import("./../../../src/pages/blogue.js" /* webpackChunkName: "component---src-pages-blogue-js" */),
  "component---src-pages-contactos-js": () => import("./../../../src/pages/contactos.js" /* webpackChunkName: "component---src-pages-contactos-js" */),
  "component---src-pages-eventos-js": () => import("./../../../src/pages/eventos.js" /* webpackChunkName: "component---src-pages-eventos-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-openday-js": () => import("./../../../src/pages/openday.js" /* webpackChunkName: "component---src-pages-openday-js" */),
  "component---src-pages-openday-ortodontia-js": () => import("./../../../src/pages/openday/ortodontia.js" /* webpackChunkName: "component---src-pages-openday-ortodontia-js" */),
  "component---src-pages-pedido-de-marcacao-js": () => import("./../../../src/pages/pedido-de-marcacao.js" /* webpackChunkName: "component---src-pages-pedido-de-marcacao-js" */),
  "component---src-pages-politica-de-privacidade-js": () => import("./../../../src/pages/politica_de_privacidade.js" /* webpackChunkName: "component---src-pages-politica-de-privacidade-js" */),
  "component---src-pages-servicos-cirurgia-oral-js": () => import("./../../../src/pages/servicos/cirurgia_oral.js" /* webpackChunkName: "component---src-pages-servicos-cirurgia-oral-js" */),
  "component---src-pages-servicos-dentisteria-js": () => import("./../../../src/pages/servicos/dentisteria.js" /* webpackChunkName: "component---src-pages-servicos-dentisteria-js" */),
  "component---src-pages-servicos-endodontia-js": () => import("./../../../src/pages/servicos/endodontia.js" /* webpackChunkName: "component---src-pages-servicos-endodontia-js" */),
  "component---src-pages-servicos-harmonizacao-facial-js": () => import("./../../../src/pages/servicos/harmonizacao-facial.js" /* webpackChunkName: "component---src-pages-servicos-harmonizacao-facial-js" */),
  "component---src-pages-servicos-higiene-oral-js": () => import("./../../../src/pages/servicos/higiene_oral.js" /* webpackChunkName: "component---src-pages-servicos-higiene-oral-js" */),
  "component---src-pages-servicos-implantologia-av-js": () => import("./../../../src/pages/servicos/implantologia_av.js" /* webpackChunkName: "component---src-pages-servicos-implantologia-av-js" */),
  "component---src-pages-servicos-index-js": () => import("./../../../src/pages/servicos/index.js" /* webpackChunkName: "component---src-pages-servicos-index-js" */),
  "component---src-pages-servicos-oclusao-js": () => import("./../../../src/pages/servicos/oclusao.js" /* webpackChunkName: "component---src-pages-servicos-oclusao-js" */),
  "component---src-pages-servicos-odontopediatria-js": () => import("./../../../src/pages/servicos/odontopediatria.js" /* webpackChunkName: "component---src-pages-servicos-odontopediatria-js" */),
  "component---src-pages-servicos-ortodontia-js": () => import("./../../../src/pages/servicos/ortodontia.js" /* webpackChunkName: "component---src-pages-servicos-ortodontia-js" */),
  "component---src-pages-servicos-periodontologia-js": () => import("./../../../src/pages/servicos/periodontologia.js" /* webpackChunkName: "component---src-pages-servicos-periodontologia-js" */),
  "component---src-pages-servicos-prostodontia-js": () => import("./../../../src/pages/servicos/prostodontia.js" /* webpackChunkName: "component---src-pages-servicos-prostodontia-js" */),
  "component---src-pages-servicos-reabilitacao-estetica-js": () => import("./../../../src/pages/servicos/reabilitacao_estetica.js" /* webpackChunkName: "component---src-pages-servicos-reabilitacao-estetica-js" */),
  "component---src-pages-sobre-js": () => import("./../../../src/pages/sobre.js" /* webpackChunkName: "component---src-pages-sobre-js" */),
  "component---src-pages-sorrisos-js": () => import("./../../../src/pages/sorrisos.js" /* webpackChunkName: "component---src-pages-sorrisos-js" */),
  "component---src-pages-termos-e-condicoes-js": () => import("./../../../src/pages/termos_e_condicoes.js" /* webpackChunkName: "component---src-pages-termos-e-condicoes-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blogTemplate.js" /* webpackChunkName: "component---src-templates-blog-template-js" */)
}

